import * as React from 'react';
import {Route, Switch, useLocation, useHistory, matchPath} from 'react-router-dom';
import NotFound from 'views/notFound/NotFound';
import {TRouteCollection} from 'routes';
import {Cover, Template, Box, Cluster, Icon, Breadcrumb, Paragraph, Popover, Stack} from '@pluto-tv/assemble';

import {version, dependencies} from '../../../package.json';
import {IBackendInfo} from 'models/backendInfo';
import {forEach} from 'lodash-es';

export interface IRouterProps {
  backendInfo?: IBackendInfo;
  getRoutes: () => TRouteCollection;
}

export default ({backendInfo, getRoutes}: IRouterProps): JSX.Element => {
  const location = useLocation();
  const history = useHistory();

  const [allRoutes, setAllRoutes] = React.useState<TRouteCollection>([]);

  const currentRouteExact = allRoutes.find(r => r.path === location.pathname);
  const currentRoute = allRoutes.filter(r => (location.pathname ? matchPath(location?.pathname, r) : false)).pop();
  const breadcrumbItems = (currentRouteExact || currentRoute)?.breadcrumb?.items;
  const assembleVersion = dependencies['@pluto-tv/assemble'];

  React.useEffect(() => {
    // The getRoutes() only needs to get called every time the user enters in the Series page
    // or when routes and allRoutes are empty
    if (location.pathname.includes('/series/') || !allRoutes.length) {
      const mapRoutes: TRouteCollection = [];
      forEach(getRoutes(), route => {
        if (route.children) {
          forEach(route.children, child => mapRoutes.push(child));
          return;
        }
        mapRoutes.push(route);
      });

      setAllRoutes(mapRoutes);
    }
  }, [allRoutes.length, getRoutes, location.pathname]);

  const breadcrumbNav = route => history.push(route);

  return (
    <Cover scrolling={true} coverTemplateHeight='100%'>
      <Template label='header'>
        <Box paddingX='large' paddingY='small' background='black'>
          <Cluster justify='space-between' align='center'>
            {breadcrumbItems && <Breadcrumb onRoute={breadcrumbNav} items={breadcrumbItems}></Breadcrumb>}
            <Popover allowedPlacements={['bottom-end', 'top-end']}>
              <Template label='trigger'>
                <Icon
                  icon='arrowdown'
                  space='xxsmall'
                  iconPosition='right'
                  verticalAlign='text-bottom'
                  size='xsmall'
                  lineHeight='0.8125rem'
                >
                  Versions
                </Icon>
              </Template>
              <Template label='popover'>
                <Box padding='xsmall'>
                  <Stack space='medium'>
                    <Paragraph id='clientVersionNumber'>App Version: {version}</Paragraph>
                    <Paragraph id='apiVersionNumber'>API Version: {backendInfo?.appVersion}</Paragraph>
                    <Paragraph id='assembleVersionNumber'>Assemble Version: {assembleVersion}</Paragraph>
                  </Stack>
                </Box>
              </Template>
            </Popover>
          </Cluster>
        </Box>
      </Template>
      <Template label='cover'>
        <Switch>
          {getRoutes().map(route => {
            if (route.children) {
              return route.children.map(r => (
                <Route key={r.name} path={r.path} component={r.component} exact={r.exact || true} />
              ));
            }
            return <Route key={route.name} path={route.path} component={route.component} exact={route.exact || true} />;
          })}
          <Route component={NotFound} />
        </Switch>
      </Template>
    </Cover>
  );
};
